import { PageWidget, IntegrationApplicationMap } from '@wix/members-area-integration-kit';

interface AppState {
  isDeletingSinglePage: boolean;
  isResponsiveEditor: boolean;
  isADI: boolean;
  integratedAppsMap: IntegrationApplicationMap;
  staticsBaseUrl: string;
  additionalWidgets: PageWidget[];
  blogWriterProfilesOnly?: boolean;
  isUninstalling: boolean;
}

let applicationState: AppState = {
  isDeletingSinglePage: false,
  isResponsiveEditor: false,
  isADI: false,
  integratedAppsMap: {},
  staticsBaseUrl: '',
  additionalWidgets: [],
  blogWriterProfilesOnly: false,
  isUninstalling: false,
};

const setIsBlogWriterProfilesOnly = (blogWriterProfilesOnly: boolean) => {
  applicationState = {
    ...applicationState,
    blogWriterProfilesOnly,
  };
};

const setIsResponsiveEditor = (isResponsiveEditor: boolean) => {
  applicationState = {
    ...applicationState,
    isResponsiveEditor,
  };
};

const setIsADI = (isADI: boolean) => {
  applicationState = {
    ...applicationState,
    isADI,
  };
};

const setStaticsBaseUrl = (staticsBaseUrl: string) => {
  applicationState = {
    ...applicationState,
    staticsBaseUrl,
  };
};

const setIntegratedApps = (integratedAppsMap: IntegrationApplicationMap) => {
  applicationState = {
    ...applicationState,
    integratedAppsMap,
  };
};

const setAdditionalWidgets = (additionalWidgets: PageWidget[]) => {
  applicationState = {
    ...applicationState,
    additionalWidgets,
  };
};

const setIsUninstalling = (isUninstalling: boolean) => {
  applicationState = {
    ...applicationState,
    isUninstalling,
  };
};

const getAdditionalWidgets = () => applicationState.additionalWidgets;
const getIsUninstalling = () => applicationState.isUninstalling;
const getIsResponsiveEditor = () => applicationState.isResponsiveEditor;
const getIsADI = () => applicationState.isADI;
const getStaticsBaseUrl = () => applicationState.staticsBaseUrl;
const getAllIntegratedApps = () => applicationState.integratedAppsMap || {};
const getVerticalsApps = (verticalAppDefId: string) => applicationState.integratedAppsMap[verticalAppDefId] || [];
const getIsBlogWriterProfilesOnly = () => applicationState.blogWriterProfilesOnly;
const removeVerticalsApps = (verticalAppDefId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete applicationState.integratedAppsMap[verticalAppDefId];
};

export {
  setIsResponsiveEditor,
  getIsResponsiveEditor,
  setIsADI,
  getIsADI,
  setStaticsBaseUrl,
  getStaticsBaseUrl,
  setIsBlogWriterProfilesOnly,
  getIsBlogWriterProfilesOnly,
  setIntegratedApps,
  getAllIntegratedApps,
  removeVerticalsApps,
  getVerticalsApps,
  setAdditionalWidgets,
  getAdditionalWidgets,
  setIsUninstalling,
  getIsUninstalling,
};
